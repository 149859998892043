@import url("https://fonts.googleapis.com/css?family=IBM+Plex+Mono:400,700|IBM+Plex+Sans:400,700|IBM+Plex+Serif:400,700");

html {
  height: 100%;
}

p {
  margin: 0;
}

textarea {
  font-family: "Open Sans", "Roboto", sans-serif;
  line-height: 1.4;
  background: #eee;
  resize: none;
}

h1 {
  margin: 20px 0 25px 0;
}

pre {
  padding: 10px;
  background-color: #eee;
}

:not(pre) > code {
  font-family: monospace;
  background-color: #eee;
  padding: 3px;
}

blockquote {
  border-left: 2px solid #ddd;
  margin-left: 0;
  margin-right: 0;
  padding-left: 10px;
  color: #aaa;
  font-style: italic;
}

a {
  color: #9d7ff2;
}

blockquote::before {
  font-family: Arial;
  color: #9d7ff2;
  font-size: 4em;
  position: absolute;
  left: 10px;
  top: -10px;
}

blockquote::after {
  content: "";
}

blockquote span {
  color: #333333;
  font-style: normal;
  font-weight: bold;
  margin-top: 1em;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.spacer {
  flex-grow: 1;
}

.format-toolbar {
  display: flex;
  border-bottom: solid 1.7px rgba(199, 198, 255, 0.15);
  padding: 10px 0;
  margin: 0 0 20px 0;
}

.tooltip-icon-button {
  cursor: pointer;
  border: 0;
}

/* fallback */
@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v41/flUhRq6tzZclQEJ-Vdg-IuiaDsNcIhQ8tQ.woff2) format("woff2");
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

.menu {
  padding: 8px 7px 6px;
  position: absolute;
  z-index: 1;
  top: -10000px;
  left: -10000px;
  opacity: 0;
  background-color: #222;
  border-radius: 4px;
  transition: opacity 0.75s;
  transform: translateY(-70px);
}

.Dashboard {
  display: flex;
  height: 15vh;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
}

.img {
  display: block;
  max-width: 100%;
  max-height: 20em;
}

.maskStyle {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
}

.wrapperStyle {
  position: relative;
}

.Toastify__toast--success {
  background: #020 !important;
  color: #0f0;
  font-family: "IBM Plex Mono" !important;
  font-weight: bold;
  min-width: 30vw;
  font-size: 18px;
  padding-left: 20px !important;
}

* {
  box-sizing: border-box;
}

.notice-block.tip {
  background: #62ff86 !important;
  color: black !important;

  a {
    color: black !important;
  }
}

