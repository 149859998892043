.confirm-container {
  font-family: "IBM Plex Mono";
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  transform: translateX(100%);
  display: none;
  justify-content: center;
  align-items: center;
  z-index: 99999;

  &.visible {
    transform: translateX(0);
    display: flex;

    .screen {
      display: block;
    }
  }

  .screen {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.8);
    display: none;
    transition: opacity 150ms ease-in-out;
    z-index: 99;
  }

  .modal {
    text-align: center;
    padding: 40px;
    width: 40vw;
    background: #fff;
    z-index: 100;
    border-radius: 10px;
    @media screen and (max-width: 1200px) {
      font-size: 12px;
    }

    h2 {
      margin: 0 0 30px 0;
      font-size: 22px;
    }
  }

  .actions {
    display: flex;
    justify-content: center;

    button {
      border: none;
      background-color: green;
      color: white;
      padding: 10px 20px;
      border-radius: 5px;
      font-size: 16px;
      font-weight: 600;
      cursor: pointer;
      margin-top: 10px;
      margin-right: 10px;
      transition: all 0.3s ease-in-out;
      :hover {
        background-color: #77dd77;
      }
    }

    button:focus {
      outline: none;
    }

    button.negative {
      background-color: #e5e7eb;
      color: black;
      :hover {
        background-color: #d1d5db;
      }
    }
  }
}
