.Header {
  display: flex;
  align-items: center;
  background: #00ff00;
  height: 60px;
  color: #005a00;
  padding: 0 20px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;



  .dropdown {
    position: relative;
    height: 50px;
    z-index: 1000;

    .dashboard {
      line-height: 50px;
    }

    .links {
      display: none;
      background: #00ee00;

      a {
        margin: 0;
        padding: 5px 30px;
      }

      a:hover {
        background: #00ff00;
      }
    }

    &:hover .links {
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 100%;
      line-height: 30px;
    }
  }

  .Logo {
    margin-right: 10px;
  }

  a {
    margin-left: 20px;
    color: #005a00;
    color: #007700;
    text-decoration: none;
  }

  .hn-logo {
    display: flex;
    align-items: center;
    margin-left: 0;
  }

  .new-post {
    background: #00bb00;
    color: #00ff00;
    padding: 8px 15px;
    border-radius: 10px;
    font-weight: 900;
    font-size: 12px;
    text-transform: uppercase;
  }

  .profile {
    width: 36px;
    height: 36px;
    border-radius: 100%;
    background-size: cover;
  }

  @media screen and (max-width: 480px) {
    overflow-x: scroll;
  }
}
