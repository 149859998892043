.App {
  *:focus {
    outline: none;
  }

  .spin {
    transform-origin: center center;
    animation-name: spin;
    animation-duration: 5000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  @keyframes spin {
    from {
      transform:rotate(0deg);
    }
    to {
      transform:rotate(360deg);
    }
  }

  #payment-form{
    button{
      background: #09a000;
      #button-text{
        font-size: 18px;
      }
    }
  }

  h1 {
    font-family: "Roboto Mono", monospace;
  }

  .spacer {
    flex-grow: 1;
  }

  main {
    background: white;
  }

  .subnav {
    background: #00dd00;
    background: #003b00;
    height: 40px;
    display: flex;
    align-items: center;
    position: fixed;
    top: 60px;
    left: 0;
    right: 0;
    z-index: 1;

    .story-link {
      text-decoration: none;
    }

    button {
      margin: 0 10px;
      padding: 0 15px;
      background: transparent;
      color: #007700;
      color: #0f0;
      font-weight: 900;
      font-size: 12px;
      text-transform: uppercase;
      height: 40px;
      border: none;
      cursor: pointer;
      transition: all 150ms ease-in-out;
      display: flex;
      align-items: center;

      i {
        margin-left: 8px;
        font-size: 18px;
      }
    }

    button.active,
    button:hover,
    a.active,
    a:hover {
      background: #005500;
      color: #00ff00;
    }
  }

  .pixelated-form {
    display: flex;
    flex-direction: column;

    section {
      margin-bottom: 1rem;

      &:not(.active) {
        display: none;
      }
    }

    label small {
      color: darkred;
    }

    input[type="submit"],
    .pixelated-button {
      font-size: 1.2em;
    }
  }

  .pixelated-input,
  .pixelated-form input:not([type="submit"]),
  .pixelated-form select,
  .pixelated-form textarea {
    border: 2px solid #212428;
    border-radius: 0;
    box-shadow: #c2cad6 0.2em 0.2em inset;
    padding: 0.5rem 1rem;
    margin: 0;
    background-color: white;
    font-family: inherit;

    &:focus {
      border-color: #0b0;
    }
  }

  .pixelated-form input[type="submit"],
  .pixelated-button {
    border: 2px solid #212428;
    box-shadow: #c2cad6 0.2em 0.2em;
    padding: 4px 8px;
    background-color: white;
    cursor: pointer;
    color: #212428;
    text-decoration: none;

    img {
      height: 20px;
      width: auto;
      image-rendering: pixelated;
      margin-right: 4px;
    }

    &:hover {
      background-color: rgb(246, 247, 249);
    }

    &:focus {
      border-color: #0b0;
    }

    &:active {
      box-shadow: rgb(194, 202, 214) 0.1em 0.1em;
      transform: translate(0.1em, 0.1em);
    }

    &[disabled] {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  .flex-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;

    &.vertical {
      flex-direction: column;

      &.align-left {
        align-items: flex-start;
      }
      &.align-right {
        align-items: flex-end;
      }
    }

    &:not(.vertical).align-left {
      justify-content: flex-start;
    }
    &:not(.vertical).align-right {
      justify-content: flex-end;
    }

    &.stretch {
      justify-content: stretch;
      align-items: stretch;

      & > * {
        flex-grow: 1;
      }
    }

    &.nogap {
      gap: 0;
    }
  }
}
