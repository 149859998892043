.top-nav.admin {
  display: none;
  .component-title {
    margin-top: 70px;
    margin-bottom: -15px;
  }
  .topsubnav {
    z-index: 100;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: sticky;
    top: 44px;

    &.bottom {
      margin-top: 50px;
    }

    .main-links {
      display: flex;
    }

    a {
      font-family: "IBM Plex Mono", monospace;
      text-decoration: none;
      margin: 0 15px;
      font-size: 14px;
      font-weight: bold;

      &.sponsor {
        color: #0080ff;
      }
    }

    .dropdown {
      min-width: 60vw;
      display: none;
      position: absolute;
      bottom: 0;
      transform: translateY(100%);
     
      font-family: "IBM Plex Mono", monospace;
      text-decoration: none;
      font-weight: bold;
      padding: 20px;
      z-index: 100;
      justify-content: center;
      align-items: start;
    }

    .dropdown.active {
      display: flex;
    }

    .dropdown .section {
      display: none;
      margin: 0 15px;
    }
    .dropdown .section.active {
      display: flex;
      flex-direction: column;
    }

    .dropdown .section.add.active {
      font-size: 48px;
      border: 3px solid #0f0;
      padding: 10px 20px;
    }

    .section .heading {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-right: 25px;
      font-size: 20px;
      position: relative;
    }

    .section .heading a {
      font-size: 20px;
      margin: 0;
    }

    

    .section .links {
      white-space: nowrap;
      display: flex;
      flex-direction: column;
      border-left: 2px solid gray;
      justify-content: start;
      padding-left: 0px;
    }

    .heading {
      display: flex;
      margin-right: auto;
    }

  

    .line {
      width: 8px;
      margin-top: 0.5rem;
      border-top: 2px solid gray;
      visibility: hidden;
    }

    .section li {
      display: flex;
      padding: 8px 0;
      list-style: none;
      color: white;
    }

    .section ul {
      :hover {
        transition: all 500ms ease;

        .line {
          visibility: visible;
        }
      }
    }

    .modal-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  @media screen and (min-width: 720px) {
    display: block;
  }
}
